.right-radius {
  border-radius: 0 12px 0 0;
}

.indicator {
  display: block;
  position: absolute;
  left: 0;
  width: 8px;
  height: auto;
  background: white;
  padding: 8px 0;
}

.active-link {
  color: #15b5b1;
}

.active-link > .indicator {
  background: #15b5b1;
}

.active-link > i {
  color: #15b5b1;
}

.page-background {
  background: linear-gradient(to bottom, #ae94ff 190px, #e5e5e5 0.1%, #e5e5e5);
}

@media (max-width: 768px) {
  .page-background {
    background: linear-gradient(to bottom, #ae94ff 140px, #e5e5e5 0.1%, #e5e5e5);
  }
}

@media (max-width: 520px) {
  .bar-container {
    width: 120px;
  }
}

.page-container {
  padding: 10px 25px;
  background: #fff;
  border-radius: 0.375rem;
}

/*** Logo upload ***/
.custom-upload {
  display: flex;
}

.custom-upload [type='file'] {
  filter: alpha(opacity=0);
  opacity: 0;
  width: 0;
}

.custom-upload .file-info {
  border: 1px solid #071333;
  border-radius: 4px;
  width: 80%;
  height: 40px;
  display: inline-flex;
  align-items: center;
  padding-left: 12px;
  margin-right: 12px;
}

.custom-upload [type='file'] + label {
  background: #15b5b1;
  border-radius: 4px;
  color: #071333;
  font-weight: 800;
  width: 25%;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom-upload [type='file'] + label:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 9px;
}

/*** Custom radio button ***/
.custom-radio[type='radio'] {
  appearance: none;
  border: none;
  content: none;
  outline: none;
  background: transparent !important;
  accent-color: transparent !important;
  color: transparent !important;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  width: 95%;
  height: 95%;
}

.custom-radio[type='radio']:checked::before {
  content: url('/checkbox-checked.svg') !important;
  transform: scale(1.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
